<template>
  <div class="">
    <div class="mb" style="padding:15px;">
      <div class="lang" v-html="lan.address"></div>:<input class="form-control mr-1 " style="width:400px;display:inline-block" type="search" placeholder="Search" aria-label="Search" v-model="address" >
      <div class="lang" v-html="lan.distance"></div>: <input class="form-control mr-1" style="width:100px;display:inline-block" type="number" placeholder="Distance" aria-label="Distance" v-model="distance" > m 
      <button type="button" class="btn btn-white" style="display:inline-block" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
        <ul class="tab-head nav nav-tabs" style="margin-top:20px">
          <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
            <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.map" ></div></a>
          </li>
          <li v-if="code!=''" class="nav-item" data-id="tc2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
            <a data-toggle="tab"  class="nav-link" aria-expanded="true"><div class="lang" v-html="lan.office_list" ></div></a>
          </li>  
        </ul>
        <div class="tab-content" style="width:100%;background:#fff">
            <div class="embed-responsive-16by9 tab-pane show active tc1" id="tc1" v-if="curindex=='tc1'">
                <div class="card-box mb-2" style="position:relative">
                <!-- map -->
                <div class="address_list" v-if="mapList.length>0"> 
                  <div v-bind:class="[cad=='k'+idNum ? 'active line':'line']" v-for="(obj, idNum) in mapList" @click="gotowindow(idNum)" v-bind:key="idNum">
                  <b> {{(obj['事業所']==undefined ?'未命名':obj['事業所'])}}</b><br>
                    <p>{{"社名 ："+(obj.Corp_Name==undefined ?'':obj.Corp_Name)}} <br>{{"住所： "+obj.address+""}}</p>
                  </div>
                </div>
                <div id="googleMap" class="google"></div>
              </div>
            </div>
            <div class="embed-responsive-16by9 tab-pane show active tc2" id="tc2" v-if="curindex=='tc2'">
                <div class="col-12">
                  <div class="responsive-table-plugin">

                    <div class="table-rep-plugin">
                      <div class="table-responsive">
                        <table class="table table-striped focus-on">
                          <thead>
                            <tr>
                              <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody v-if="mapList.length>0">
                            <tr v-for="(obj, idNum) in mapList" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)">
                              
                              <td >
                              {{(obj['Corp_Name']==undefined ?'':obj['Corp_Name'])}}
                              </td>
                               <td >
                              {{(obj['事業所']==undefined ?'未命名':obj['事業所'])}}
                              </td>
                               <td >
                              {{(obj['address']==undefined ?'':obj['address'])}}
                              </td>
                               <td >
                              {{(obj['distance']==undefined ?'':obj['distance'])}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-2">
                          <code v-if="mapList.length==0"><div class="lang" v-html="lan.no_data"></div></code>
                        </div>
                      </div> <!-- end .table-responsive -->
                    </div> <!-- end .table-rep-plugin-->
                    </div>
                </div>
            </div>
          </div>
      
    </div>
   
  </div>
</template>

<script>
import router from '@/router'
import axios from 'axios';
import { inject } from 'vue';
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
export default {
  name: "GoogleMaps",
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: currentRoute.value.query.address==undefined ? "東京都千代田区丸の内2-1-1":currentRoute.value.query.address,
      distance:currentRoute.value.query.distance==undefined ? 20000:currentRoute.value.query.distance,
      mapList:[],
      reload: 0,
      cad:'',
      map:null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker:{},
          InfoWindow:{},
          Circle:{},
        },
      },
      markers:[],
      infowindow:[],
      curindex:"tc1",
       lan:inject('global').language_data,
      lan_help:inject('global').language_help_data,
      tableHeaders:["Office",'Corp Name',"Address","Distance"],
    };
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.google = window.google;
      this.initializeMap();
    });
  },
  methods: {
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    tab:function(tid){
      this.curindex=tid;
      if(tid=="tc1"){
        var that=this;
        setTimeout(function(){
          that.initializeMap();
        },800)
          
      }
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");
      
      const { Map, MapTypeId, Marker,InfoWindow,Circle} = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();
      var _that=this;
      this.cad='';
      // map create
      var zoom=7
      if(_that.distance>3000000){
        zoom=3
      }else if(_that.distance>2000000){
        zoom=4
      }else if(_that.distance>1000000){
        zoom=5
      }else if(_that.distance>500000){
        zoom=6
      }else if(_that.distance>200000){
        zoom=7
      }else if(_that.distance>100000){
        zoom=8
      }else if(_that.distance>50000){
        zoom=9
      }else if(_that.distance>20000){
        zoom=10
      }else if(_that.distance>10000){
        zoom=11
      }else if(_that.distance>5000){
        zoom=12
      }else if(_that.distance>2000){
        zoom=13
      }else if(_that.distance>1000){
        zoom=14
      }else if(_that.distance>500){
        zoom=15
      }else if(_that.distance>200){
        zoom=16
      }else if(_that.distance>100){
        zoom=17
      }else if(_that.distance>50){
        zoom=18
      }else if(_that.distance>20){
        zoom=19
      }else{
        zoom=20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

    
      
      new Geocoder.geocode({ address: _that.address }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          let center = {
            lat: Number(locate.lat()),
            lng: Number(locate.lng()),
          };

          // set locate
          _that.map.setCenter(center);
          new Circle({
            strokeColor: "#003f2d",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#003f2d",
            fillOpacity: 0.35,
            map:_that.map,
            center: center,
            radius: parseInt(_that.distance),
          });
          //_that.mapList.push({latitude:locate.lat(),longitude:locate.lng(),address:_that.address})
          _that.markers=[];
          _that.infowindow=[];
          if(_that.mapList.length>0){
            _that.mapList.map((obj,i) => {
               _that.infowindow[i] = new InfoWindow({
                content: '<div id="content">' +
                            '<div id="siteNotice">' +
                            "</div>" +
                            '<h3 id="firstHeading" class="firstHeading">'+(obj.Corp_Name==undefined ?'':obj.Corp_Name)+'</h3>' +
                            '<div id="bodyContent">' +
                            "<p>事業所 ："+(obj['事業所']==undefined ?'未命名':obj['事業所'])+"<br>住所： "+obj.address+"</p>" +
                            "</div>" +
                            "</div>"
              });
              _that.markers[i]=new Marker({
                position: {
                          lat: parseFloat(obj.latitude),
                          lng: parseFloat(obj.longitude)},
                map:_that.map,
                title: obj.Corp_Name==undefined ?'':obj.Corp_Name,
              });
              _that.markers[i].addListener("click", () => {
              _that.infowindow[i].open({
                anchor: _that.markers[i],
                map:_that.map,
                shouldFocus: false
              });
            });

            });
            
           
          }  
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },
    search(){

      axios.get(process.env.VUE_APP_API2_URL_BASE+'/getLocationByAddresRecommendation?address='+ this.address+'&distance='+ this.distance,{params:{id_token:localStorage.getItem('id_token')}})
        .then(response => {
            this.mapList=response.data.data;
            this.initializeMap();
        })
        .catch(error => (
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          )
        );
    },
    gotowindow(i){
      var _that=this;
      if(this.cad!=''){
        _that.infowindow[this.cad.replace('k','')].close();
      }
      this.cad='k'+i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map:_that.map,
        shouldFocus: false
      });
    }
  },
  watch: {

    $route(){
      const { currentRoute } = router;
      this.distance=currentRoute.value.query.distance==undefined ? 20000:currentRoute.value.query.distance;
      this.address=currentRoute.value.query.address==undefined ? '短期業績好調企業一覧': currentRoute.value.query.address;
    },
    reload: function() {
      this.initializeMap()
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 800px;
}
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}
.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}
.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}
.row {
  font-size: 14px;
}
pre {
  font-family: 'Ubuntu Mono';
  font-size: 14px;
}
.row {
  zoom: 1;
  margin-left: -20px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}
.row:after {
  clear: both;
}
.row>[class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}
.span1 {
  width: 40px;
}
.span2 {
  width: 100px;
}
.span3 {
  width: 160px;
}
.span4 {
  width: 220px;
}
.span5 {
  width: 280px;
}
.span6 {
  width: 340px;
}
.span7 {
  width: 400px;
}
.span8 {
  width: 460px;
}
.span9 {
  width: 520px;
}
.span10 {
  width: 580px;
}
.span11 {
  width: 640px;
}
.span12 {
  width: 700px;
}
.span13 {
  width: 760px;
}
.span14 {
  width: 820px;
}
.span15 {
  width: 880px;
}
.span16 {
  width: 940px;
}
.span17 {
  width: 1000px;
}
.span18 {
  width: 1060px;
}
.span19 {
  width: 1120px;
}
.span20 {
  width: 1180px;
}
.span21 {
  width: 1240px;
}
.span22 {
  width: 1300px;
}
.span23 {
  width: 1360px;
}
.span24 {
  width: 1420px;
}
pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notice {
  background-color: #3a87ad;
}
form input[type=text] {
  border: solid 1px #999999;
  padding: 2px;
}
.edit_marker {
  width: 250px;
  height: 80px;
}
.edit_marker p {
  margin: 0;
}
.address_list {position:absolute; top:80px;left:30px;z-index: 999;background: #FFF;max-height: 500px;overflow-y: auto;}
.address_list .line{border-top:1px solid #eee;padding: 5px;margin:0 5px;     cursor: pointer;}
.address_list .line:nth-child(0){
  border-top:0;
}
.address_list .active{background: #003f2d; color:#fff;}
.nav-tabs li{cursor: pointer;}
</style>
